<template>
  <!-- TODO: use IconButton (ENG-4305) -->
  <button
    :title="name"
    class="auth-button d-flex justify-content-center align-items-center px-xs py-sm"
    @click="emit('click')">
    <slot/>
  </button>
</template>

<script setup>
const emit = defineEmits(['click'])

defineProps({
  name: {
    type: String,
    required: true
  }
})
</script>

<style lang="scss" scoped>
.auth-button {
  border: 0.5px solid $ash;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0px 1px 8px 0px rgba($armor, 0.10);
  height: 64px;
}
</style>
