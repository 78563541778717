<template>
  <div ref="googleLoginBtn"/>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'signup',
      validator: value => {
        return value.match(/(signup|login)/)
      }
    },
    width: {
      type: Number,
      default: 400
    }
  },
  data () {
    return {
      params: {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID
      }
    }
  },
  watch: {
    width (width) {
      window.google?.accounts.id.renderButton(
        this.$refs.googleLoginBtn, {
          text: this.variant === 'signup' ? 'signup_with' : 'continue_with',
          size: 'large',
          width, // max width 400
          theme: 'outline'
        }
      )
    }
  },
  mounted () {
    if (typeof window.google === 'undefined') {
      const script = document.createElement('script')
      script.id = '_google-sign-in-script'
      script.type = 'text/javascript'
      script.onload = () => {
        const gClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID
        window.google.accounts.id.initialize({
          client_id: gClientId,
          callback: this.handleCredentialResponse,
          prompt_parent_id: this.id
        })
        const width = this.width
        window.google.accounts.id.renderButton(
          this.$refs.googleLoginBtn, {
            text: this.variant === 'signup' ? 'signup_with' : 'continue_with',
            size: 'large',
            width: width, // max width 400
            theme: 'outline'
          }
        )
      }
      script.src = 'https://accounts.google.com/gsi/client'
      document.head.appendChild(script)
    }
  },
  methods: {
    async handleCredentialResponse (response) {
      const formData = {
        google_token: response.credential
      }
      this.$emit('success', formData)
    }
  }
}
</script>
