<template>
  <AuthButton
    name="Facebook"
    @click="onClick">
    <SvgFacebook
      height="26"
      width="26"/>
  </AuthButton>
</template>

<script setup>
import { onMounted } from 'vue'
import AuthButton from './AuthButton.vue'
import SvgFacebook from '@/components/global/svg/SvgFacebook.vue'
import { MobileAppMessage } from '@/utils/mobileApp.js'
import useMobileApp from '@/composables/mobileApp.js'

const emit = defineEmits(['success', 'error'])

onMounted(() => {
  if (!document.getElementById('fb-root')) {
    const fbRoot = document.createElement('div')
    fbRoot.id = 'fb-root'
    document.body.insertAdjacentElement('afterbegin', fbRoot)
  }
  if (window.fbAsyncInit === undefined) {
    window.fbAsyncInit = function () {
      // eslint-disable-next-line no-undef
      FB.init({
        appId: '335461220309715',
        cookie: true,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v3.0'
      })
    }
  }
  (function () {
    const scriptId = 'facebook-jssdk'
    if (document.getElementById(scriptId)) return
    const script = document.createElement('script')
    script.id = scriptId
    script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0&appId=335461220309715&autoLogAppEvents=1'
    document.head.appendChild(script)
  }())
})

function signIn () {
  if (typeof FB === 'undefined') {
    emit('error', 'Please disable any ad-blockers and reload the page to login with Facebook.')
    return
  }
  // eslint-disable-next-line no-undef
  FB.login(
    ({ status, authResponse }) => {
      if (status === 'connected') {
        const formData = {
          facebook_token: authResponse.accessToken
        }
        emit('success', formData)
      }
    },
    { scope: 'email,public_profile' }
  )
}

const { inWebView, post } = useMobileApp()
function onClick () {
  if (inWebView()) {
    post(MobileAppMessage.SignUpWithFacebook)
  } else {
    signIn()
  }
}
</script>
