import { computed, inject, onMounted, toValue, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useClosetStore } from '@shared/stores/closet.js'
import logger from '@/logger'
import { until } from '@vueuse/core'
import useMyStuff from './myStuff.js'
import { useClientStore } from '@shared/stores/client.js'
import { getSectionRouteLocation, getSectionHref } from '@shared/utils/route.js'
import useLink from '@shared/composables/link.js'

/**
 * @param {string} sectionId
 */
export default function useClosetSection (sectionId, { loadOnMount = false } = {}) {
  const closetStore = useClosetStore()
  const { isAuthenticated } = storeToRefs(useClientStore())

  const { initialSectionsLoaded, styleColorsMap } = storeToRefs(closetStore)
  const { getSectionById } = closetStore

  const id = computed(() => toValue(sectionId) ?? null)
  const section = computed(() => getSectionById(id.value))
  const loaded = computed(() => itemCount.value !== null)
  const name = computed(() => {
    if (source.value === 'favorites') {
      return 'Favorites'
    }
    return section.value?.name
  })
  const source = computed(() => section.value?.source)
  const isFavorites = computed(() => source.value === 'favorites')
  const isDislikes = computed(() => source.value === 'dislikes')
  const subtitle = computed(() => {
    if (isFavorites.value) {
      return 'Items you love will appear here'
    }
    if (isDislikes.value) {
      return 'Items you don\'t love will appear here'
    }
    return section.value?.subtitle
  })
  const description = computed(() => section.value?.description)
  const itemCount = computed(() => section.value?.length ?? null)
  const isEmpty = computed(() => !(itemCount.value > 0))
  const itemCountText = computed(() =>
    typeof itemCount.value === 'number'
      ? `${itemCount.value} item${itemCount.value === 1 ? '' : 's'}`
      : null
  )
  const tileSubtitle = computed(() => {
    if (isEmpty.value && (isFavorites.value || isDislikes.value)) {
      return subtitle.value
    }
    return itemCountText.value
  })
  const styleColorIds = computed(() => section.value?.styleColors ?? [])
  const styleColorIdsLoaded = computed(() => itemCount.value === styleColorIds.value.length)
  const styleColors = computed(() =>
    styleColorIds.value
      .map(styleColorId => styleColorsMap.value[styleColorId])
      .filter(styleColor => !!styleColor)
  )
  const styleColorsLoaded = computed(() => loaded.value && styleColors.value.length === styleColorIds.value.length && styleColors.value.length > 0)
  const bannerImageUrl = computed(() => section.value?.bannerImageUrl)
  const previewImageUrls = computed(() => styleColors.value.map(styleColor => styleColor.images.front.url))
  const routeLocation = computed(() => getSectionRouteLocation(section.value, isAuthenticated.value))
  const href = computed(() => getSectionHref(section.value, isAuthenticated.value))
  const link = useLink(href)

  const dedupeKey = inject('dedupeKey', undefined)
  async function loadStyleColors (amount = 20) {
    if (id.value) {
      return closetStore.loadStyleColors({
        id: id.value,
        amount,
        dedupeKey
      })
    } else {
      loadOnMount = true
    }
  }
  function navigate () {
    link.activate()
  }

  async function load () {
    if (!id.value) {
      return
    }
    await until(initialSectionsLoaded).toBe(true)
    if (!section.value) {
      await closetStore.getSection({ id: id.value })
    }
    if (!styleColorIdsLoaded.value) {
      await loadStyleColors()
    }
  }
  onMounted(() => {
    if (loadOnMount) {
      load()
    }
  })
  watch(id, () => {
    if (loadOnMount) {
      load()
    }
  })

  return {
    data: section,
    id,
    loaded,
    name,
    source,
    isFavorites,
    isDislikes,
    subtitle,
    description,
    itemCount,
    isEmpty,
    itemCountText,
    tileSubtitle,
    styleColorIds,
    styleColorIdsLoaded,
    styleColors,
    styleColorsLoaded,
    bannerImageUrl,
    previewImageUrls,
    routeLocation,
    href,
    loadStyleColors,
    navigate
  }
}

/**
 * @param {function} predicate
 */
export function useClosetSectionMatching (predicate, { loadOnMount = false } = {}) {
  const closetStore = useClosetStore()
  const sectionId = computed(() => {
    const sections = closetStore.sections.filter(predicate)
    if (sections.length !== 1 && closetStore.allSectionsLoaded) {
      logger.warn('Exactly 1 section should match predicate', { sections })
    }
    return sections[0]?.id
  })
  return useClosetSection(sectionId, { loadOnMount })
}

/**
 * @param {string} source
 */
export function useClosetSectionBySource (source, { loadOnMount = false } = {}) {
  return useClosetSectionMatching(section => section.source === toValue(source), { loadOnMount })
}

export function useFavoritesSection ({ loadOnMount = false } = {}) {
  useMyStuff()
  return useClosetSectionBySource('favorites', { loadOnMount })
}

export function useWearItAgainSection ({ loadOnMount = false } = {}) {
  useMyStuff()
  return useClosetSectionBySource('rewear', { loadOnMount })
}
