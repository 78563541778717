<template>
  <div class="sign-up-page">
    <SignUpPageHeader/>
    <RouterView
      :key="$route.name"
      #default="{ Component }">
      <Transition
        name="fade"
        mode="out-in">
        <component :is="Component"/>
      </Transition>
    </RouterView>
  </div>
</template>

<script setup>
import SignUpPageHeader from '@/components/signUp/SignUpPageHeader.vue'
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'
import { useSubscriptionStore } from '@/stores/subscribe.js'
import { storeToRefs } from 'pinia'
import { onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const signUpNav = useSignUpNavigationStore()

// Keep the store in sync with the current route.
const route = useRoute()
watch(route, (to) => { signUpNav.goTo(to.name) }, { immediate: true })

// Keep the router in sync with the current screen.
const router = useRouter()
const { currentScreenRouterLink } = storeToRefs(signUpNav)
watch(currentScreenRouterLink, (to) => { router.push(to) }, { immediate: true })

const subscriptionStore = useSubscriptionStore()
const { parseQueryParams } = subscriptionStore
onMounted(async () => {
  await parseQueryParams(route)
})
</script>

<style lang="scss" scoped>
.sign-up-page {
  height: 100dvh;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  @include adorn-gradient;
}
</style>
