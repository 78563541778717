<template>
  <PlainButton
    class="filters-button"
    @click="openFiltersFlyout(styleColorSet)">
    <SvgCircleIcon
      :width="32"
      :height="32"
      :padding="14"
      :stroke="2"
      variant="plum">
      <SvgFilters/>
    </SvgCircleIcon>
    <TextLink
      variant="primary-new"
      @click="flyouts.openFiltersFlyout(styleColorSet)">
      Filters:
    </TextLink>
  </PlainButton>
</template>

<script setup>
import { useFlyouts } from '@/composables/flyouts.js'
import PlainButton from '@/components/global/sequin/PlainButton.vue'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgFilters from '@/components/global/svg/SvgFilters.vue'
import TextLink from '@/components/global/sequin/TextLink.vue'

defineProps({
  styleColorSet: {
    type: Object,
    required: true
  }
})

const { openFiltersFlyout } = useFlyouts()
</script>
