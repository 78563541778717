<template>
  <div class="closet bg-white">
    <div class="col px-0">
      <router-view #default="{ Component }">
        <keep-alive include="MyCloset, ClosetPageSection">
          <component
            :is="Component"
            :key="$route.path"
            class="custom-fade"/>
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script>
import { mapActions as mapVuexActions, mapState } from 'vuex'
import { mapActions } from 'pinia'
import { track } from '../../plugins/analytics'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  computed: {
    ...mapState('closet', [
      'ratingsMap',
      'selectedItemTypes'
    ]),
    ...mapState('client', [
      'isAuthenticated'
    ])
  },
  mounted () {
    const query = { ...this.$route.query }
    const allowedFlyoutLinks = ['core', 'surprise-case']
    if ('myCase' in query) {
      if (allowedFlyoutLinks.includes(query.myCase)) {
        this.setMyCaseFlyoutName(query.myCase)
        this.openFlyout({ name: 'my-case' })
        if (query.latestCase === 'true') track('Viewed My Latest Case')
      }
      delete query.myCase
      delete query.latestCase
      this.$router.replace({ ...this.$route, query })
    }
  },
  methods: {
    ...mapVuexActions('case', [
      'setMyCaseFlyoutName'
    ]),
    ...mapActions(useOverlaysStore, [
      'openFlyout'
    ])
  }

}
</script>

<style lang="scss" scoped>
  .closet {
    min-height:100vh;
  }

  /*
    Vue transitions have issues with keep-alive, so we should stick with
    a simple CSS animation for now (https://github.com/quasarframework/quasar/issues/1466)
   */
  .custom-fade {
    animation: appear .5s ease;
    @keyframes appear {
      0% { opacity: 0 }
      100% { opacity: 1 }
    }
  }
</style>
