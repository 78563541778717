<template>
  <accordion-with-header
    :expanded="expanded"
    :expanded-max-height="600"
    title="Return labels and packaging"
    @accordion-click="e => $emit('accordion-click', e)">
    <div class="mt-2">
      <p>
        Each Case is delivered in a reusable mailer made of fully-recycled materials. Learn more about Armoire's commitment to sustainable packaging <text-link
          size="paragraph"
          class="py-0"
          type="a"
          :href="PACKAGING_BLOG_URL">
          here
        </text-link>.
      </p>
      <p>
        To return your items, pack them up in the reusable mailer. Affix the included return label on the outside of the package, and drop the package off at your nearest
        <text-link
          type="a"
          size="paragraph"
          class="py-0"
          target="_blank"
          :href="FEDEX_LOCATOR_URL">
          <strong>FedEx</strong> drop point
        </text-link>
        or schedule a free
        <text-link
          type="a"
          size="paragraph"
          class="py-0"
          target="_blank"
          :href="FEDEX_PICKUP_URL">
          <strong>FedEx</strong> pickup
        </text-link>
        or
        <text-link
          type="a"
          size="paragraph"
          class="py-0"
          target="_blank"
          :href="USPS_PICKUP_URL">
          <strong>USPS</strong> pickup
        </text-link>.
      </p>
      <p>
        Lost your return label? <text-link
          type="a"
          class="py-0"
          size="paragraph"
          :href="printReturnLabelLink">
          Print a new one
        </text-link>.
      </p>
    </div>
  </accordion-with-header>
</template>

<script>
import AccordionWithHeader from '../../global/sequin/AccordionWithHeader.vue'
import ReturnsMixin from './ReturnsMixin'
import ShippingAddressMixin from '../ShippingAddressMixin'
import TextLink from '../../global/sequin/TextLink.vue'
export default {
  components: {
    AccordionWithHeader,
    TextLink
  },
  mixins: [ReturnsMixin, ShippingAddressMixin],
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  }
}
</script>
