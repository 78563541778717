<template>
  <div
    class="navigation-menu"
    :class="{ 'expanded': navBar.isExpanded }">
    <div
      v-if="!navBar.isNotificationsPanelOpen"
      class="menu-profile-section d-flex"
      :class="dynamicProfileSectionClasses">
      <PlainButton
        v-if="isAuthenticated"
        class="profile-section-button d-flex align-items-center"
        @click="navBar.toggleProfilePanel">
        <SvgChevronLeft
          v-if="navBar.isProfilePanelOpen"
          class="chevron-wrapper custom-fade"/>
        <div
          class="d-flex flex-grow-1"
          :class="navBar.isProfilePanelOpen ? 'animate-slide-profile' : 'animate-slide-profile-right'">
          <div class="menu-item-icon-wrapper d-flex justify-content-center">
            <ProfileImage/>
          </div>
          <div
            class="name-button">
            <div :class="dynamicNavigationMenuItemNameClasses">
              Hi {{ clientFirstName }}
            </div>
            <SvgChevronRight
              v-if="!navBar.isProfilePanelOpen"
              class="me-md custom-fade"
              :class="dynamicNavigationMenuItemNameClasses"/>
          </div>
        </div>
      </PlainButton>
    </div>
    <div class="menu-list-section">
      <NotificationNavPanel
        v-if="navBar.isNotificationsPanelOpen"
        class="notifications-list custom-fade-fast"/>
      <div
        v-else
        class="nav-item-list">
        <BaseLink
          v-for="item in visibleNavItems"
          :key="item.name"
          :to="item.to"
          class="menu-item d-flex align-items-center custom-fade-fast"
          :class="{
            'menu-item-expanded': navBar.isExpanded && !navBar.isProfilePanelOpen,
            'menu-item profile': navBar.isProfilePanelOpen,
            active: item.active
          }"
          @click="handleNavButtonClick(item)">
          <div
            class="menu-item-icon-wrapper d-flex justify-content-center"
            :class="{ 'icon-wrapper-expanded' : navBar.isExpanded, 'prospect' : !isAuthenticated, 'icon-wrapper-profile' : navBar.isProfilePanelOpen }">
            <Component
              :is="item.icon"
              class="menu-item-icon"/>
          </div>
          <div
            :class="dynamicNavigationMenuItemNameClasses">
            {{ item.name }}
          </div>
        </BaseLink>
      </div>
    </div>
    <div
      v-if="!navBar.isProfilePanelOpen && isAuthenticated"
      class="menu-notifications-title-section menu-item menu-profile-section "
      :class="{ 'order-first ps-xs expanded custom-fade-fast -slide' : navBar.isNotificationsPanelOpen }">
      <PlainButton
        class="notification-section-button d-flex align-items-center w-100 p-0"
        @click="navBar.toggleNotificationsPanel">
        <SvgChevronLeft
          v-if="navBar.isNotificationsPanelOpen"
          class="chevron-wrapper custom-fade"/>
        <div
          class=" d-flex justify-content-center"
          :class="{'menu-item-icon-wrapper' : !navBar.isNotificationsPanelOpen}">
          <NotificationsIcon class="menu-item-icon"/>
        </div>
        <div
          class="d-flex w-100 justify-content-between align-items-center">
          <p
            class="flex-grow-1 mb-0"
            :class="dynamicNavigationMenuItemNameClasses">
            Notifications
          </p>
          <SvgChevronRight
            v-if="!navBar.isNotificationsPanelOpen"
            class="chevron-wrapper me-md"
            :class="dynamicNavigationMenuItemNameClasses"/>
        </div>
      </PlainButton>
    </div>
    <div
      v-if="navBar.isNotificationsPanelOpen"
      class="menu-notifications-settings-section custom-fade-fast">
      <PlainButton
        class="text-button"
        href="/account/notifications"
        @click="handleSettingsClick()">
        Settings
      </PlainButton>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft.vue'
import SvgChevronRight from '@/components/global/svg/SvgChevronRight.vue'
import ProfileImage from '@/components/community/common/ProfileImage.vue'
import PlainButton from '@/components/global/sequin/PlainButton.vue'
import NotificationsIcon from '@/components/global/NotificationsIcon.vue'
import NotificationNavPanel from '@/components/navigation/notifications/NotificationNavPanel.vue'
import useNavigation from '@/composables/navigation.js'
import useClient from '@/composables/client.js'
import useScreenSize from '@shared/composables/screenSize.js'
import { useRouter } from 'vue-router'
import BaseLink from '@shared/components/BaseLink.vue'

const { isTabletOrSmaller } = useScreenSize()

const router = useRouter()
const {
  isAuthenticated,
  firstName: clientFirstName
} = useClient()

const {
  navBar,
  signedOutNavItems,
  profilePanelItems,
  mobileNavItems,
  navItems
} = useNavigation()

function handleNavButtonClick (navItem) {
  navBar.collapse()
  navItem.onClick?.()
}
function handleSettingsClick () {
  navBar.collapse()
  router.push({ path: '/account/notifications' })
}

const dynamicNavigationMenuItemNameClasses = computed(() => ({
  'text-button menu-item-name': !navBar.isExpanded,
  'text-button menu-item-name-expanded': navBar.isExpanded && !navBar.isProfilePanelOpen,
  'text-button menu-item-name-profile': navBar.isProfilePanelOpen
}))
const dynamicProfileSectionClasses = computed(() => ({
  'justify-content-between': navBar.isProfilePanelOpen,
  'justify-content-center': !navBar.isProfilePanelOpen,
  'border-dark-bottom': navBar.isExpanded
}))
const visibleNavItems = computed(() => {
  if (!isAuthenticated.value) {
    return signedOutNavItems.value
  }
  if (navBar.isProfilePanelOpen) {
    return profilePanelItems.value
  }
  return isTabletOrSmaller.value ? mobileNavItems.value : navItems.value
})
</script>

<style scoped lang="scss">
@import "./Navigation.scss";
</style>
