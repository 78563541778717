<!-- Layout component for Sign Up screens -->
<template>
  <div :class="['sign-up-screen', { phase1: signUpPhase1 }]">
    <SignUpPageHeader v-if="signUpPhase1"/>
    <div class="sign-up-screen-content px-md pb-md">
      <div
        v-if="heading || subheading"
        class="d-flex flex-column row-gap-sm text-center pt-xl pb-lg">
        <h2 v-if="heading">
          {{ heading }}
        </h2>
        <p v-if="subheading">
          {{ subheading }}
        </p>
      </div>
      <slot/>
    </div>
    <SignUpPageFooter @next="emit('next')">
      <template #additional-content>
        <slot name="additional-footer-content"/>
      </template>
    </SignUpPageFooter>
  </div>
</template>

<script setup>
import SignUpPageHeader from '@/components/signUp/SignUpPageHeader.vue'
import SignUpPageFooter from '@/components/signUp/SignUpPageFooter.vue'
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'
import { onUnmounted } from 'vue'
import { useSignUpExperiments } from '@shared/composables/experiment.js'

defineProps({
  heading: {
    type: String,
    default: null
  },
  subheading: {
    type: String,
    default: null
  }
})

const { saveStyleProfileChanges } = useSignUpNavigationStore()
onUnmounted(async () => { await saveStyleProfileChanges() })

const emit = defineEmits(['next'])

const { signUpPhase1 } = useSignUpExperiments()
</script>

<style lang="scss" scoped>
.sign-up-screen {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  &.phase1 {
    grid-template-rows: auto 1fr auto;
  }
}
</style>
