<template>
  <div
    v-if="isTabletOrSmaller"
    class="filter-section">
    <AvailabilityToggle
      v-if="styleColorSet.showAvailabilityToggle"
      :style-color-set="styleColorSet"
      @on-available-now-toggle="emit('on-available-now-toggle')"/>
    <p
      class="whitespace-no-wrap m-0 semi-bold"
      :class="{'w-100' : !styleColorSet.showAvailabilityToggle}">
      {{ length }} items
    </p>
    <FilterTagsSection
      v-if="selectedFilters.length > 0"
      class="filter-tags-wrapper mt-sm"
      :selected-filters="selectedFilters"
      @clear-all-filters="emit('clear-all-filters')"
      @remove-filter="emit('remove-filter', $event)"/>
  </div>
  <div
    v-else
    class="filter-section">
    <AvailabilityToggle
      v-if="styleColorSet.showAvailabilityToggle"
      :style-color-set="styleColorSet"
      @on-available-now-toggle="emit('on-available-now-toggle')"/>
    <FilterTagsSection
      v-if="selectedFilters?.length > 0"
      class="filter-tags-wrapper"
      :selected-filters="selectedFilters"
      @clear-all-filters="emit('clear-all-filters')"
      @remove-filter="emit('remove-filter', $event)"/>
    <div class="filter-button-items-length d-flex justify-content-end align-items-center">
      <OpenFilterFlyoutButton
        :style-color-set="styleColorSet"/>
      <p class="whitespace-no-wrap m-0 semi-bold">
        {{ length }} items
      </p>
    </div>
  </div>
</template>

<script setup>
import useScreenSize from '@shared/composables/screenSize.js'
import useStyleColorSet from '@/composables/styleColorSet.js'
import AvailabilityToggle from '@/components/styleColorSet/filtersFlyout/AvailabilityToggle.vue'
import OpenFilterFlyoutButton from '@/components/styleColorSet/filtersFlyout/OpenFilterFlyoutButton.vue'
import FilterTagsSection from '@/components/styleColorSet/filtersFlyout/FilterTagsSection.vue'

const { isTabletOrSmaller } = useScreenSize()

const props = defineProps({
  styleColorSet: {
    type: Object,
    required: true
  },
  selectedFilters: {
    type: Array,
    required: true
  }
})

const emit = defineEmits([
  'on-available-now-toggle',
  'clear-all-filters',
  'remove-filter'
])

const { length } = useStyleColorSet(() => props.styleColorSet)

</script>

<style lang="scss" scoped>
.filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  @include media-tablet-or-smaller {
    flex-wrap: wrap;
  }
}
.filters-button {
  padding: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.filter-tags-wrapper {
  display: flex;
  overflow: hidden;
}
.filter-button-items-length {
  flex: 1 1 auto;
}
.filter-tags {
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
}
.filter-tags-clear-button {
  flex: 1 0 auto;
  margin: 0 8px;
  @include media-tablet-or-smaller {
    margin-left: 0;
  }
}
</style>
