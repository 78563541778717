<template>
  <SignUpScreen @next="personalizeNow">
    <template #default>
      <div class="text-center">
        Onboarding
      </div>
    </template>
    <template #additional-footer-content>
      <BaseButton
        v-if="signUpPhase2"
        text="Subscribe now, personalize later"
        variant="text-link"
        :to="nextScreenRouterLink"
        @click="personalizeLater"/>
    </template>
  </SignUpScreen>
</template>

<script setup>
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import { useSignUpExperiments } from '@shared/composables/experiment.js'
import { SignUpMode, useSignUpNavigationStore } from '@/stores/signUpNavigation.js'
import { storeToRefs } from 'pinia'

const { signUpPhase2 } = useSignUpExperiments()

const { mode, nextScreenRouterLink } = storeToRefs(useSignUpNavigationStore())
function personalizeNow () {
  mode.value = SignUpMode.Default
}
function personalizeLater () {
  mode.value = SignUpMode.PersonalizeLater
}
</script>

<style scoped lang=scss>
</style>
