<template>
  <AuthButton
    name="Google"
    @click="onClick">
    <SvgGoogle
      height="26"
      width="26"/>
    <div
      ref="googleButtonWrapper"
      class="d-none"/>
  </AuthButton>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import AuthButton from './AuthButton.vue'
import SvgGoogle from '@/components/global/svg/SvgGoogle.vue'
import useMobileApp, { MobileAppMessage } from '@/composables/mobileApp.js'

const emit = defineEmits(['success'])

const googleButtonWrapper = ref(null)

onMounted(() => {
  if (typeof window.google === 'undefined') {
    const script = document.createElement('script')
    script.id = '_google-sign-in-script'
    script.type = 'text/javascript'
    script.onload = () => {
      const googleClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID
      window.google.accounts.id.initialize({
        client_id: googleClientId,
        ux_mode: 'popup',
        callback
      })
      window.google.accounts.id.renderButton(
        googleButtonWrapper.value,
        {
          type: 'icon',
          theme: 'outline'
        }
      )
    }
    script.src = 'https://accounts.google.com/gsi/client'
    document.head.appendChild(script)
  }
})

function callback ({ credential }) {
  const formData = {
    googleToken: credential
  }
  emit('success', formData)
}

const { inWebView, post } = useMobileApp()
function onClick () {
  if (inWebView()) {
    post(MobileAppMessage.SignUpWithGoogle)
  } else {
    // Click the real Google button in the hidden iframe.
    googleButtonWrapper.value.querySelector('div[role="button"]').click()
  }
}
</script>
