import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import useScreenSize from '@shared/composables/screenSize.js'
import { useRoute } from 'vue-router'

export const useNavBarStore = defineStore('navBar', () => {
  const state = ref('collapsed')
  const manualCollapse = ref(false)
  const isCollapsed = computed(() => state.value === 'collapsed')
  const isExpanded = computed(() => state.value.startsWith('expanded'))
  const isProfilePanelOpen = computed(() => state.value === 'expanded-profile')
  const isNotificationsPanelOpen = computed(() => state.value === 'expanded-notifications')
  const isPanelOpen = computed(() => isProfilePanelOpen.value || isNotificationsPanelOpen.value)

  const canGoBack = ref(false)
  const route = useRoute()
  watch(route, () => { canGoBack.value = (history.state?.back ?? null) !== null })

  const { isTabletOrSmaller } = useScreenSize()

  const collapse = (source) => {
    if (source === 'mouseleave' || isTabletOrSmaller.value) {
      manualCollapse.value = false
    } else {
      manualCollapse.value = true
    }
    state.value = 'collapsed'
  }
  const expand = () => {
    if (!manualCollapse.value) {
      state.value = 'expanded'
    }
  }
  const openProfilePanel = () => { state.value = 'expanded-profile' }
  const toggleProfilePanel = () => { isProfilePanelOpen.value ? expand() : openProfilePanel() }
  const openNotificationsPanel = () => { state.value = 'expanded-notifications' }
  const toggleNotificationsPanel = () => { isNotificationsPanelOpen.value ? expand() : openNotificationsPanel() }

  return {
    state,
    isCollapsed,
    isExpanded,
    isProfilePanelOpen,
    isNotificationsPanelOpen,
    isPanelOpen,
    canGoBack,
    collapse,
    expand,
    openProfilePanel,
    toggleProfilePanel,
    openNotificationsPanel,
    toggleNotificationsPanel
  }
})
